import  React from "react";
import firestore from "firestore";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import {DropzoneArea} from 'material-ui-dropzone'
import {OutTable, ExcelRenderer} from 'react-excel-renderer';


class ConfiguracionSeleccionPortafolio extends React.Component {

constructor (props) {
        super (props)    
        this.state ={   
          nits : [],
          porcentaje : 0
       };   
}
  
 handleChange(e){

  console.log("------- EVENT ----------");
  console.log(e);
  try{
  let fileObj = e[0];
   ExcelRenderer(fileObj, (err, resp) => {
    if(err){
      console.log(err);            
    }
    else{
        let archivoXLS = [];
        resp.rows.forEach(_row => {
          console.log(_row[0]);
            archivoXLS.push(_row[0]+"");
        });     

        this.setState({ nits : archivoXLS})
    }
  });     
  }catch(error)
  {}

}



 handleChange1(e){
  this.setState({
    [e.target.id]: e.target.value
  })
}



saveDataCupos(e,submitModalCupos){
  console.log(this.state);
  const db = firebase.firestore();          
  db.collection('APIPortafoliosTuApp').doc("portafolio").set(this.state).then(function(result){

 
  }).catch(function(error)
  {
    console.log(error);
 
  });  
}



saveDataCuposCorporativo(e,submitModalCupos){
  console.log(this.state);
  const db = firebase.firestore();          
  db.collection('APIPortafoliosTuAp').doc("portafolio").set(this.state).then(function(result){

  }).catch(function(error)
  {
    console.log(error);
 
  });  
}
render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted,submitModalCupos,seleccionArchivo} = this.props;

const rows = rowData;

  
const styles = { border: '1px solid black', width: 600, color: 'black', padding: 20 };

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">


        { (seleccionArchivo == "0")  ? 
          <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Asignacion de Portafolio a Segmentos</DialogTitle> 
         :  
         <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Asignacion de Portafolio a Segmentos  </DialogTitle> 
         }
         
        <DialogContent dividers>

        <DropzoneArea 
        onChange={this.handleChange.bind(this)}  acceptedFiles={['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          dropzoneText="Por Favor Selecciona el Archivo" 
        />

<br></br>
<br></br>

{

            (seleccionArchivo == "0")   ?        <TextField            required            margin="dense"            id="porcentaje"            onKeyUp={this.handleChange1.bind(this)}                     onChange={this.handleChange1.bind(this)}                    label="Porcentaje"            type="text"            defaultValue={"0"}            fullWidth                    />   : ''
}

 
       </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
        <Button  style={{color :"white"}}   onClick={closeModal} color="primary">
        Cerrar
      </Button>
      </td>

  {
    (seleccionArchivo == "0")   ?   
    <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button   style={{color :"#26c6da"}}   onClick={closeModal} color="primary">
            Guardar
          </Button>
          </td>
  : 
  <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}   onClick={closeModal} color="primary">
          Guardar
        </Button>      
        </td>
}  
 

</tr>
</table>

     
        </DialogActions>
      </Dialog>
    )
 }
}

export default ConfiguracionSeleccionPortafolio;