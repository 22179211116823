import  React from "react";
import firebase from "firebase";
import MaterialTable from "material-table";
import DialogIntegrarProveedorDetalleReporte from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalleReporte";

import DialogIntegrarProveedorDetalleReporteQuery from "./DialogIntegrarProveedorDetalleReporteQuery";

import uuid from 'uuid-random';
import https from 'https';

import { executeDynamicQuery , FirebaseDynamicEmpresa} from 'dbdriverdinamic'

class IntegrarProveedorDetalleReportes extends React.Component {

  constructor(props) {
      super(props);


      try{

      this.state = {
        data: [] ,
        modalIsOpen: false,
        modalIsOpenReporte :false,
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor:props.location.state.nombre_proveedor,
        codigo_empresa: props.location.state.codigo_empresa,
        nombre_empresa: props.location.state.nombre_empresa,
        listadoServicios : []
      };

         const db = firebase.firestore();
 
         let citiesRef = db.collection('APIIntegracionProveedoresDetalleReportes').where('codigo_proveedor', '==', props.location.state.codigo_proveedor).where('codigo_empresa', '==', props.location.state.codigo_empresa);
         let query = citiesRef.onSnapshot(snapshot => {
             const _data = snapshot.docs.map(doc => doc.data());
              

             db.collection('APIServicios')
             .onSnapshot((snapshot1) => {
                  const _data11 = snapshot1.docs.map(doc1 => doc1.data());
                  
                  this.setState({listadoServicios : _data11});
    
                  var objtoPass = _data11.reduce(function(acc, cur, i) {
                   acc[cur.nombre] = cur.nombre;
                   return acc;
                   }, {});

              this.setState({ columns: [ 
             {
                title: 'Reporte',
                field: 'reporte',
                editable: 'onAdd' 
             },
                             {
                              title: 'Servicio',
                              field: 'servicio',
                              lookup: objtoPass,
                           },
                             {
                              title: 'Tiempo Actualizacion',
                              field: 'tiempo',
                              lookup: {  0: 'En Linea',  1: '1 Minuto' , 2:'2 Minutos' , 3 :'3 Minutos', 4 :'4 Minutos',  5: '5 Minutos',10: '10 Minutos',15: '15 Minutos',30: '30 Minutos',45: '45 Minutos',60: '1 Hora',120: '2 Horas',720: '12 Horas',1440 : '1 Dia' }
                            }
                      
                           ],
                           data: _data, 
                           actions : [
                            {
                              icon: 'search',
                              tooltip: 'Detalle Reporte',
                              onClick: (event,rowdata) => this.setState({modalIsOpen: true,isEdited: false,rowData:rowdata,isDeleted:false})
                            },
                            {
                              icon: 'input',
                              tooltip: 'Agregar Parametros al reporte',
                              onClick: (event,rowdata) => this.setState({modalIsOpen: true,isEdited: true,rowData:rowdata,isDeleted:false})
                            },
                            {
                              icon: 'receipt',
                              tooltip: 'query',
                              onClick: (event,rowdata) =>  { 
                           
                                this.setState({rowData :rowdata,modalIsOpenReporte : true});

                              }
                            }
                          ]
           });
 
            }, (error) => {
              console.log(error);
            });
          
          }); 
                     
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalReporte = this.closeModalReporte.bind(this);
  

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
        window.location.href = '/admin/dashboard'; 
      }

       }catch 
       {
            window.location.href = '/admin/dashboard'; 
       }  
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
    }   

    closeModalReporte() {
      this.setState({modalIsOpenReporte: false});
    }   

    

render(){

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const tituloIntegraciones = " proveedor : " + this.state.nombre_proveedor + " - empresa : "+ this.state.nombre_empresa ;

return (
            <div>
     <MaterialTable
      title={tituloIntegraciones}
      columns={this.state.columns}
      data={this.state.data}
      actions={this.state.actions}
      options={{
        exportButton: true
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout( () => {
              resolve();
              const data = [...this.state.data];
              data.push(newData);
              console.log(newData);
              let query = "";
              let tiempo = "";
              query = newData.query;
              tiempo = newData.tiempo;
           
              const db = firebase.firestore();    
              db.collection('APIIntegracionProveedoresDetalleReportes').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+newData.reporte).set(
                { 
                   codigo_empresa:this.state.codigo_empresa,
                   nombre_empresa:this.state.nombre_empresa,
                   codigo_proveedor:   this.state.codigo_proveedor,
                   nombre_proveedor: this.state.nombre_proveedor,
                   reporte : newData.reporte,
                   tiempo: tiempo,
                   servicio :    newData.servicio
                }
                ).then(function(result){
                      
              }).catch(function(error)
              {
  
              });  
           

             // this.setState({ ...this.state, data });
            }, 600);
          }), 
          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout( () => {
              resolve();
              const data = [...this.state.data];
              data.push(newData);
              console.log(newData);
              let query = "";
              let tiempo = "";
              query = newData.query;
              tiempo = newData.tiempo;
           
              const db = firebase.firestore();    
              db.collection('APIIntegracionProveedoresDetalleReportes').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+newData.reporte).set(
                { 
                   codigo_empresa:this.state.codigo_empresa,
                   nombre_empresa:this.state.nombre_empresa,
                   codigo_proveedor:   this.state.codigo_proveedor,
                   nombre_proveedor: this.state.nombre_proveedor,
                   query : query,
                   reporte : newData.reporte,
                   tiempo: tiempo ,
                   servicio :  newData.servicio
                }
                ).then(function(result){
                      
              }).catch(function(error)
              {
  
              });  
           

             // this.setState({ ...this.state, data });
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...this.state.data];
              data.splice(data.indexOf(oldData), 1);

                 const db = firebase.firestore();    
                  db.collection('APIIntegracionProveedoresDetalleReportes').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+oldData.reporte).delete().then(function(result)
                  {}).catch(function(error)
                  {});
            }, 600);
          }),
      }}
    />    
    
              <DialogIntegrarProveedorDetalleReporte modalIsOpen={this.state.modalIsOpen}  closeModal={this.closeModal}  isEdited={this.state.isEdited}  rowData={this.state.rowData} isDeleted={this.state.isDeleted}  listadoEmpresas={this.state.listadoEmpresas}  />
              <DialogIntegrarProveedorDetalleReporteQuery modalIsOpenReporte={this.state.modalIsOpenReporte}  closeModalReporte={this.closeModalReporte}   isEdited={this.state.isEdited}  rowData={this.state.rowData} />
            </div>
        );        
    }
}

export default IntegrarProveedorDetalleReportes;