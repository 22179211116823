import  React from "react";
import firebase from "firebase";
import MaterialTable from "material-table";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DialogIntegrarProveedorDetalle from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalle";
import DialogProveedorDetalleRespuesta from "views/IntegrarProveedorDetalle/DialogIntegrarProveedorDetalleRespuesta";

import DialogIntegrarProveedorDetalleQuery from "./DialogIntegrarProveedorDetalleQuery";


import uuid from 'uuid-random';
import https from 'https';

import { executeDynamicQuery , FirebaseDynamicEmpresa} from 'dbdriverdinamic'

class IntegrarProveedorDetalle extends React.Component {

  constructor(props) {
      super(props);


      try{

      this.state = {
        data: [] ,
        modalIsOpen: false,
        modal2IsOpen : false,
        modalIsOpenReporte : false,
        responseServicio :"",
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor:props.location.state.nombre_proveedor,
        codigo_empresa: props.location.state.codigo_empresa,
        nombre_empresa: props.location.state.nombre_empresa,
        listadoServicios : []
      };

         const db = firebase.firestore();
 
         let citiesRef = db.collection('APIIntegracionProveedoresDetalle').where('codigo_proveedor', '==', props.location.state.codigo_proveedor).where('codigo_empresa', '==', props.location.state.codigo_empresa);
         let query = citiesRef.onSnapshot(snapshot => {
             const _data = snapshot.docs.map(doc => doc.data());
             db.collection('APIServicios')
         .onSnapshot((snapshot1) => {
              const _data11 = snapshot1.docs.map(doc1 => doc1.data());
              
              this.setState({listadoServicios : _data11});

              var objtoPass = _data11.reduce(function(acc, cur, i) {
               acc[cur.nombre] = cur.nombre;
               return acc;
               }, {});
 
              this.setState({ columns: [ 
             {
                title: 'Servicio',
                field: 'servicio',
                editable: 'onAdd',
                lookup: objtoPass,
             },
                         
                             {
                              title: 'Tiempo Actualizacion',
                              field: 'tiempo',
                              lookup: { 0: 'En Linea',  1: '1 Minuto' , 2:'2 Minutos' , 3 :'3 Minutos', 4 :'4 Minutos',  5: '5 Minutos',10: '10 Minutos',15: '15 Minutos',30: '30 Minutos',45: '45 Minutos',60: '1 Hora',120: '2 Horas',720: '12 Horas',1440 : '1 Dia' }
                            }
                            ,
                            {
                             title: 'Operacion',
                             field: 'operacion',
                             lookup: { "consulta": "Consulta","exportacion": "Envio Remoto"}
                           }
                           ],
                           data: _data, 
                           actions : [
                            {
                              icon: 'backup',
                              tooltip: 'Configurar Exportacion',
                              onClick: (event,rowdata) => this.setState({modalIsOpen: true,isEdited: true,rowData:rowdata,isDeleted:false})
                            }
                            ,{
                              icon: 'build',
                              tooltip: 'Configuracion Avanzada',
                              onClick: (event,rowdata) =>   this.props.history.push({
                                pathname : '/admin/integrarproveedordetalleconfiguracionservicio',
                                state :{
                                codigo_proveedor : rowdata.codigo_proveedor,
                                nombre_proveedor : rowdata.nombre_proveedor,
                                codigo_empresa : rowdata.codigo_empresa,
                                nombre_empresa : rowdata.nombre_empresa,
                                servicio  :rowdata.servicio
                                }
                                } 
                              ) 
                            },
                            {
                              icon: 'receipt',
                              tooltip: 'query',
                              onClick: (event,rowdata) =>  { 
                           
                                this.setState({rowData :rowdata,modalIsOpenReporte : true});

                              }
                            }    
                          ]
           });
 
            }, (error) => {
              console.log(error);
            });
             
           });
        
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
        this.closeModalReporte  = this.closeModalReporte.bind(this);
        

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
        window.location.href = '/admin/dashboard'; 
      }

       }catch 
       {
            window.location.href = '/admin/dashboard'; 
       }  
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});


    }   

    closeModalReporte() {
      this.setState({modalIsOpenReporte: false});
    }   


    closeModal2() {
      this.setState({modal2IsOpen: false});
    }   

render(){

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const tituloIntegraciones = " proveedor : " + this.state.nombre_proveedor + " - empresa : "+ this.state.nombre_empresa ;

var those = this;

return (
            <div>
     <MaterialTable
      title={tituloIntegraciones}
      columns={this.state.columns}
      data={this.state.data}
      actions={this.state.actions}
      options={{
        exportButton: true
      }}
      editable={{
        onRowAdd: newData =>
        
        

        new Promise( async resolve => {

          const data = [...this.state.data];
          data.push(newData);
          console.log(newData);
          let query = "";
          let tiempo = "";
          query = newData.query;
          tiempo = newData.tiempo;
          this.state.listadoServicios.forEach(function(element) {
                if (element.nombre == newData.servicio && ( newData.query == "" ||  typeof newData.query  === 'undefined' )  )
                {
                  query =  element.query;
                }

                if (element.nombre == newData.servicio && ( newData.tiempo == "" ||  typeof newData.tiempo  === 'undefined' )  )
                {
                  tiempo =  element.tiempo;
                }
          });


          const db =  firebase.firestore();    

          if (typeof newData.operacion === 'undefined') 
          {
            newData["operacion"] = "consulta";
          }        

          if (typeof newData.accion === 'undefined') 
          {
            newData["accion"] = "1";
          }

          if (typeof newData.url === 'undefined') 
          {
            newData["url"] = "";
          }

          if (typeof newData.metodo === 'undefined') 
          {
            newData["metodo"] = "";
          }

          if (typeof newData.path === 'undefined') 
          {
            newData["path"] = "";
          }

        await  db.collection('APIIntegracionProveedoresDetalle').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+newData.servicio).set(
            { 
                codigo_empresa:this.state.codigo_empresa,
                nombre_empresa:this.state.nombre_empresa,
                codigo_proveedor:   this.state.codigo_proveedor,
                nombre_proveedor: this.state.nombre_proveedor,
                query : query,
                servicio : newData.servicio,
                tiempo: tiempo,
                operacion : newData.operacion,
                accion : newData.accion,
                url : newData.url ,
                metodo :newData.metodo,
                path : newData.path 
            }
            ).then(function(____result){
              console.log("resultad");
              console.log(____result);
          }).catch(function(error)
          {
             console.log(error);
          });   

          resolve();

        })
         , onRowUpdate:  (newData, oldData) =>
    
        new Promise( async resolve => {

            const data = [...this.state.data];
            data.push(newData);
            console.log(newData);
            let query = "";
            let tiempo = "";
            query = newData.query;
            tiempo = newData.tiempo;
            this.state.listadoServicios.forEach(function(element) {
                  if (element.nombre == newData.servicio && ( newData.query == "" ||  typeof newData.query  === 'undefined' )  )
                  {
                    query =  element.query;
                  }

                  if (element.nombre == newData.servicio && ( newData.tiempo == "" ||  typeof newData.tiempo  === 'undefined' )  )
                  {
                    tiempo =  element.tiempo;
                  }
            });

 
            const db =  firebase.firestore();    

       
            if (typeof newData.operacion === 'undefined') 
            {
              newData["operacion"] = "consulta";
            }
          
            if (typeof newData.accion === 'undefined') 
            {
              newData["accion"] = "1";
            }

            if (typeof newData.url === 'undefined') 
            {
              newData["url"] = "";
            }

            if (typeof newData.metodo === 'undefined') 
            {
              newData["metodo"] = "";
            }

            if (typeof newData.path === 'undefined') 
            {
              newData["path"] = "";
            }

          await  db.collection('APIIntegracionProveedoresDetalle').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+newData.servicio).set(
              { 
                  codigo_empresa:this.state.codigo_empresa,
                  nombre_empresa:this.state.nombre_empresa,
                  codigo_proveedor:   this.state.codigo_proveedor,
                  nombre_proveedor: this.state.nombre_proveedor,
                  query : query,
                  servicio : newData.servicio,
                  tiempo: tiempo,
                  operacion : newData.operacion,
                  accion : newData.accion,
                  url : newData.url ,
                  metodo :newData.metodo,
                  path : newData.path    
              }
              ).then(function(____result){
                console.log("resultad");
                console.log(____result);
            }).catch(function(error)
            {
               console.log(error);

              });   

              resolve();
          })
          ,
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...this.state.data];
              data.splice(data.indexOf(oldData), 1);

                 const db = firebase.firestore();    
                  db.collection('APIIntegracionProveedoresDetalle').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+oldData.servicio).delete().then(function(result)
                  {}).catch(function(error)
                  {});
            }, 600);
          }),
      }}
    />    
     <DialogIntegrarProveedorDetalle modalIsOpen={this.state.modalIsOpen}  closeModal={this.closeModal}  isEdited={this.state.isEdited}  rowData={this.state.rowData} isDeleted={this.state.isDeleted}  listadoEmpresas={this.state.listadoEmpresas}  />

      <DialogProveedorDetalleRespuesta modalIsOpen={this.state.modal2IsOpen}  closeModal={this.closeModal2} responseServicio={this.state.responseServicio}  />
                   
      <DialogIntegrarProveedorDetalleQuery modalIsOpenReporte={this.state.modalIsOpenReporte}  closeModalReporte={this.closeModalReporte}   isEdited={this.state.isEdited}  rowData={this.state.rowData} />
                        
            </div>
        );        
    }

}

 
export default IntegrarProveedorDetalle;


