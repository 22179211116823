import  React from "react";
import firebase from "firebase";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

class Usuario extends React.Component {

  constructor(props) {
      super(props);

      console.log("STEP 1 - A ");

      this.state = {
        data: [] ,
        modalIsOpen: false,
        isEdited: false,
        rowData: {},
        isDeleted: false 
      };

      console.log("STEP 1 - B ");
       const db = firebase.firestore();
        db.collection('APIUsuarios')
        .onSnapshot((snapshot) => {
          const _data = snapshot.docs.map(doc => doc.data());
          this.setState({ columns: [
                            { title: 'Cedula', field: 'cedula' },
                            { title: 'Nombre', field: 'nombre'  },
                            { title: 'Email', field: 'email'  } 
                          ],
                          data: _data,
                          actions : [
                            {
                              icon: 'search',
                              tooltip: 'Detalle Usuario',
                              onClick: (event,rowdata) => this.setState({modalIsOpen: true,isEdited: false,rowData:rowdata,isDeleted:false})
                            },
                            {
                              icon: 'add_box',
                              tooltip: 'Agregar Usuario',
                              isFreeAction: true,
                              onClick: (event) =>  this.setState({modalIsOpen: true,isEdited: true,rowData:null,isDeleted:false,isDeleted:false})
                            },{
                              icon: 'edit',
                              tooltip: 'Editar Usuario',
                              onClick: (event,rowdata) =>  this.setState({modalIsOpen: true,isEdited: true,rowData:rowdata,isDeleted:false})  
                            },{
                              icon: 'delete_outline',
                              tooltip: 'Eliminar Usuario',
                              onClick: (event,rowdata) =>   this.setState({modalIsOpen: true,isEdited: false,rowData:rowdata,isDeleted:true})
                            }
                          ]
          });
        }, (error) => {
          console.log(error);
        });
        
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        const storedUsuario = localStorage.getItem("usuario");
        if (storedUsuario === null) {
          window.location.href = '/admin/dashboard'; 
        }
        console.log("STEP 1 - C ");
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
    }   

render(){
 
  console.log("STEP 1 - D ");

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  console.log("STEP 1 - E ");

return (
            <div>
              <MaterialTable
                  title="Listado de Usuarios"
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={this.state.actions}
                  options={{
                    exportButton: true
                  }}
                />
                <DialogUsuario modalIsOpen={this.state.modalIsOpen}  closeModal={this.closeModal}  isEdited={this.state.isEdited}  rowData={this.state.rowData} isDeleted={this.state.isDeleted}  />
              
            </div>
        );        
    }
}

export default Usuario;