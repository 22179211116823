import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import firebase from "firebase";
// @material-ui/icons
import FacebookLogin from 'react-facebook-login';
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";

import LoginModal from "./LoginForm";
 
import { facebookConfig, googleConfig } from "./socialconfig";



import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

   

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      this.state = {
        showModal: true,
        loggedIn: null,
        loading: false,
        error: null,
        initialTab: null,
        recoverPasswordSuccess: null
      };

    }else
    {
      this.state = {
        showModal: false,
        loggedIn: null,
        loading: false,
        error: null,
        initialTab: null,
        recoverPasswordSuccess: null
      };
    }

  }


  toggleModal = () => {
   // this.setState({ showModal: !this.state.showModal });
  };

  handleLoginWithFacebook = () => {
    // Do something when 'Login with Facebook' is clicked
    console.log("Login with Facebook...");
  };

  handleLoginByEmail = (username, password) => {
    // Do something when 'Signup by email' is clicked
    console.log("Sign up by email...___"+username+"___"+password);

        const db = firebase.firestore();
        let usuariosRef = db.collection('APIUsuarios').where('email', '==', username).where('clave', '==', password);
        let query = usuariosRef.onSnapshot(snapshot => {
            const _data = snapshot.docs.map(doc => doc.data());
            if(_data.length == 0)
            {
                this.setState({showModal: true});
                localStorage.removeItem("usuario");
            }else
            {

                localStorage.setItem('usuario',  JSON.stringify(_data[0]));
            //    this.setState({showModal: false});
                 window.location.reload();
            }

            console.log(_data);
           }, (error) => {
             console.log(error);
           });
  };

  render(){
    const loggedIn = this.state.loggedIn;
    const isLoading = this.state.loading;

 const responseFacebook = (response) => {
      console.log(response);
    }

    const labelsCustom = {
      loginTitle: "Ingreso",
      signupTitle: "Crear  Nuevo Usuario",
      forgotTitle: "Resetear Contraseña",
      loginFacebookButton: "Ingreso con Facebook",
      loginGoogleButton: "Ingreso con Google",
      loginEmailButton: "Ingresar",
      signupFacebookButton: "",
      signupGoogleButton: "",
      signupEmailButton: "",
      forgotButton: "",
      loginEmailPlaceholder: "Usuario",
      loginPasswordPlaceholder: "Clave",
      signupUsernamePlaceholder: "Usuario",
      signupLink: "",
      loginLink: "",
      forgotLink: "",
      orLabel: "o"
    };


 

   const textStyles = {color :"black"}

  const customUsernameRegex = /^[a-zA-Z0-9_]{5,}/;
  const classes = {cardCategory : "jss253",cardTitle:"jss255" };// useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}  style={textStyles} >Consultas generadas al API </p>
              <h3 className={classes.cardTitle} style={textStyles}>
                14 <small>Peticiones</small>
              </h3>
            </CardHeader>
            
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}  style={textStyles}  >Total pedidos procesados Exitosamente</p>
              <h3 className={classes.cardTitle}  style={textStyles}      >245</h3>
            </CardHeader>
         
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}  style={textStyles}      >Pedidos no procesados por error</p>
              <h3 className={classes.cardTitle}  style={textStyles}     >75</h3>
            </CardHeader>
      
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}  style={textStyles}    >Total Integraciones Configuradas</p>
              <h3 className={classes.cardTitle}  style={textStyles}    >+245</h3>
            </CardHeader>
   
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}   style={textStyles} >Estadisticas uso</h4>
              <p className={classes.cardCategory}    style={textStyles}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                 incremento llamados por dia
              </p>
            </CardBody>
          
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Integraciones realizadas</h4>
              <p className={classes.cardCategory}>Estadisticas ultimo año</p>
            </CardBody>
        
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Transacciones Diarias</h4>
              <p className={classes.cardCategory}>Estadisticas ultimo dia</p>
            </CardBody>
           
          </Card>
        </GridItem>
      </GridContainer>
     <LoginModal
          labels={labelsCustom}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          onLoginFacebook={this.handleLoginWithFacebook}
          onLoginEmail={this.handleLoginByEmail}
          usernameRegex={customUsernameRegex}
        />
    </div>
  );
 }
}

export default Dashboard;
