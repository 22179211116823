import  React from "react";
import firestore from "firestore";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import sqlFormatter from "sql-formatter";
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DialogIntegrarProveedorDetalleQuery extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()
    if(nextProps.rowData != null)
    {
      
        this.setState({   
          codigo_empresa:nextProps.rowData.codigo_empresa,
          nombre_empresa:nextProps.rowData.nombre_empresa,
          codigo_proveedor:   nextProps.rowData.codigo_proveedor,
          nombre_proveedor: nextProps.rowData.nombre_proveedor,
          query : nextProps.rowData.query,
          servicio : nextProps.rowData.servicio,
          tiempo: nextProps.rowData.tiempo,
          operacion : nextProps.rowData.operacion,
          accion : nextProps.rowData.accion,
          url : nextProps.rowData.url ,
          metodo :nextProps.rowData.metodo,
          path : nextProps.rowData.path,
          active : false 
        });
    }else
    {
        this.setState({   
          codigo_empresa: "",
          nombre_empresa:"",
          codigo_proveedor:   "",
          nombre_proveedor: "",
          query : "",
          servicio : "",
          tiempo: "",
          operacion : "",
          accion : "",
          url : "",
          metodo : "",
          path : "",
          active : false 
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

  onSubmit = async (e,closeModal) => {
            e.preventDefault();
            this.setState({ active :true});
            const db =  firebase.firestore();    
        await  db.collection('APIIntegracionProveedoresDetalle').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.servicio).set(
              { 
                  codigo_empresa:this.state.codigo_empresa,
                  nombre_empresa:this.state.nombre_empresa,
                  codigo_proveedor:   this.state.codigo_proveedor,
                  nombre_proveedor: this.state.nombre_proveedor,
                  query : this.state.query,
                  servicio : this.state.servicio,
                  tiempo: this.state.tiempo,
                  operacion : this.state.operacion,
                  accion : this.state.accion,
                  url : this.state.url ,
                  metodo :this.state.metodo,
                  path :  (( this.state.path == null || typeof this.state.path  == "undefined") ? "" :  this.state.path )   
              }
              ).then(function(____result){
                console.log("resultad");
                console.log(____result);
            }).catch(function(error)
            {
               console.log(error);
            });   

            const _headers = {  
              'Content-Type': 'application/json', 
              'Accept': 'application/json', 
              'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
              'TROPI-TOKEN' : '65b4b563-9c4c-4694-b03e-8652e006e2d1'
            }

            
            const urlData = "https://us-central1-gcp-tropi-gaussia.cloudfunctions.net/generarSchemaFirestore?codigo_empresa="+this.state.codigo_empresa+"&codigo_proveedor="+this.state.codigo_proveedor +"&servicio="+this.state.servicio;
            console.log(urlData);
         
            await    axios.get(urlData , {_headers : _headers})
            .then((res) => {
         
  
              console.log(res.data.mensaje);
 

              this.setState({ active :true});

            })
            .catch((error) => {
                console.log(error)
            })        

            closeModal();
}

render()
{
  const {modalIsOpenReporte, closeModalReporte,rowData} = this.props;

  const rows = rowData;
  let _showsql = "";

  console.log("<------------------------->");
  console.log(rowData.query );
  console.log("<------------------------->");

  if (typeof rowData.query === 'undefined'){
      console.log(" null value");
  }else 
  {
    _showsql  = sqlFormatter.format(rowData.query);
  }
 

  const styledata = {"font-size": "12px"};

  
    return(
        <Dialog open={modalIsOpenReporte} onClose={closeModalReporte} aria-labelledby="form-dialog-title">
      <LoadingOverlay
      active={ this.state == null ? false : this.state.active}
      spinner={<BounceLoader />}
    >
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Edicion Query </DialogTitle> 
     
        <DialogContent dividers>

        
        <textarea rows="30" cols="80" id="query"     onKeyUp={this.handleChange.bind(this)}      style={styledata}    
        onChange={this.handleChange.bind(this)}     >
                    {_showsql}
        </textarea>

        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
 
          <Button   style={{color :"white"}}    onClick={(e) => this.onSubmit(e,closeModalReporte)} color="primary">
            Guardar
          </Button>
          </td> 

           <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}    onClick={closeModalReporte} color="primary">
            Cerrar
          </Button>
          </td> 

          </tr>
          </table>

        </DialogActions>
        </LoadingOverlay>
      </Dialog>
   
    )
 }
}

export default DialogIntegrarProveedorDetalleQuery;