import React from "react";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CardHeader from "@material-ui/core/Card";



class DialogSeleccionCupo extends React.Component {

constructor (props) {
        super (props)    
        this.state ={
          seleccion_archivo : "1"
       };   
}

handleChange(e){
  console.log(e);
  this.setState({
    "seleccion_archivo": e.target.value
  })
}

submitModalSeleccionArchivo(e,submitModalSeleccionArchivo){
  submitModalSeleccionArchivo(this.state.seleccion_archivo);
}
 
render()
{
const {modalIsOpen, closeModalSeleccionArchivo,isEdited,rowData,isDeleted,submitModalSeleccionArchivo} = this.props;


 

const rows = rowData;

const styles = { border: '1px solid black', width: 600, color: 'black', padding: 20 };

    return(
     
        <Dialog open={modalIsOpen} onClose={closeModalSeleccionArchivo} aria-labelledby="form-dialog-title">
      
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Importar Archivo Cupos  </DialogTitle> 
        
        <DialogContent dividers>
        <FormControl component="fieldset">
        <RadioGroup aria-label="seleccion_archivo" name="seleccion_archivo" value={this.state.seleccion_archivo} onChange={this.handleChange.bind(this)}>
          <FormControlLabel value="0" control={<Radio color="primary"  />} label="Asignacion Cupo a Sucursales" /> 
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Cupo Corporativo" />
        </RadioGroup>
      </FormControl>
        
       </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
      <table style={{width: "100%"}}>
      <tr>
      <td align="center" style={{backgroundColor :"#26c6da"}}>
          <Button  style={{color :"white"}} onClick={(e) => this.submitModalSeleccionArchivo(e,submitModalSeleccionArchivo)} color="primary">
              Aceptar
            </Button>
      </td>
      <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button onClick={closeModalSeleccionArchivo}  style={{color :  "#26c6da"  }}>
            Cerrar
          </Button>
          </td>  
          </tr>
     </table>
        </DialogActions>
      </Dialog>
 
    )
 }
}

export default DialogSeleccionCupo;