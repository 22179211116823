import React from "react";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



class DialogSeleccionCupo extends React.Component {

constructor (props) {
        super (props)    
        this.state = {   
          seleccion_cupo : "1"
       };   
}

handleChange(e){
  console.log(e);
  this.setState({
    "seleccion_cupo": e.target.value
  })
}


sendDataSiesa(e,submitModalCuposSiesa){
  submitModalCuposSiesa(this.state.seleccion_cupo);
}
 
render()
{
const {modalIsOpen, closeModalSeleccionCupo,isEdited,rowData,isDeleted,submitModalCuposSiesa} = this.props;

const rows = rowData;

const styles = { border: '1px solid black', width: 600, color: 'black', padding: 20 };

    return(
        <Dialog open={modalIsOpen} onClose={closeModalSeleccionCupo} aria-labelledby="form-dialog-title">
          <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Selecciona la  opcion que deseas realizar  </DialogTitle> 
        <DialogContent dividers>
 
        <FormControl component="fieldset">
        <RadioGroup aria-label="listaprecio" name="seleccion_cupo" value={this.state == null ? "1" : this.state.seleccion_cupo} onChange={this.handleChange.bind(this)}>
          <FormControlLabel value="0" control={<Radio color="primary"  />} label="Restablecer cupos" /> 
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Enviar nuevo calculo de cupo" />
          <FormControlLabel value="2" control={<Radio color="primary" />} label="Asociar sucursales corporativas" />
        </RadioGroup>
      </FormControl>
        
       </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>

        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

        <Button  style={{color :"white"}}   onClick={(e) => this.sendDataSiesa(e,submitModalCuposSiesa)} color="primary">
            Aceptar
          </Button>
          </td>
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button   style={{color :"#26c6da"}}  onClick={closeModalSeleccionCupo} color="primary">
            Cerrar
          </Button>
          </td>
          </tr>
          </table>
     
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogSeleccionCupo;