import firebase from 'firebase'

/**
 *  
const config = {
   apiKey: "AIzaSyCgCIPPm7cpVNyKyn1OeXPbAXZa2pH-cO4",
   authDomain: "yuba-5a1ac.firebaseapp.com",
   databaseURL: "https://yuba-5a1ac.firebaseio.com",
   projectId: "yuba-5a1ac",
   storageBucket: "yuba-5a1ac.appspot.com",
   messagingSenderId: "177167562230",
   appId: "1:177167562230:web:de25f0a2691d84774143d7"
};

*/

const config = {
   apiKey: "AIzaSyBNvR3HRbGksjcqNtohWC44vTfoRrnudBA",
   authDomain: "gcp-tropi-gaussia.firebaseapp.com",
   databaseURL: "https://gcp-tropi-gaussia.firebaseio.com",
   projectId: "gcp-tropi-gaussia",
   storageBucket: "gcp-tropi-gaussia.appspot.com",
   messagingSenderId: "731100857857",
   appId: "1:731100857857:web:c896a3777d11eaa559e200",
   measurementId: "G-2Z828PE3QV"
 };

firebase.initializeApp(config);

export default firebase;