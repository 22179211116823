import  React from "react";
import firebase from "firebase";
import MaterialTable from "material-table";
import uuid from 'uuid-random';
import jwt from 'jwt-simple';

class IntegrarProveedor extends React.Component {

  constructor(props) {
      super(props);

      try{

      this.state = {
        data: [] ,
        modalIsOpen: false,
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor:props.location.state.nombre_proveedor,
        listadoEmpresas : []
      };


      console.log("CODIGO_PROVEEDOR: "+props.location.state.codigo_proveedor,)

       const db = firebase.firestore();
 
        let citiesRef = db.collection('APIIntegracionProveedores').where('codigo_proveedor', '==', props.location.state.codigo_proveedor);
        let query = citiesRef.onSnapshot(snapshot => {
            const _data = snapshot.docs.map(doc => doc.data());
            db.collection('APIEmpresas')
        .onSnapshot((snapshot1) => {
             const _data11 = snapshot1.docs.map(doc1 => doc1.data());

             var objtoPass = _data11.reduce(function(acc, cur, i) {
              acc[cur.codigo] = cur.nombre;
              return acc;
              }, {});

             this.setState({ columns: [ 
            {
               title: 'Empresa',
               field: 'codigo_empresa',
               lookup: objtoPass,
            },
                            { title: 'Access Token', field: 'access_key' ,  editable: 'never'  },
                            { title: 'Request Token', field: 'token' ,  editable: 'never' },
                
                          ],
                          data: _data,
                          listadoEmpresas :_data11,
                          actions : [
                            {
                              icon: 'http',
                              tooltip: 'Asignar',
                              onClick: (event,rowdata) =>  this.props.history.push({
                                pathname : '/admin/integrarproveedordetalle',
                                state :{
                                          codigo_proveedor :  this.state.codigo_proveedor,
                                          nombre_proveedor :  this.state.nombre_proveedor,
                                          codigo_empresa: rowdata.codigo_empresa,
                                          nombre_empresa: rowdata.nombre_empresa
                                }
                                } 
                              ) 
                            } ,
                            {
                              icon: 'list_alt',
                              tooltip: 'Reportes',
                              onClick: (event,rowdata) =>  this.props.history.push({
                                pathname : '/admin/integrarproveedordetallereportes',
                                state :{
                                          codigo_proveedor :  this.state.codigo_proveedor,
                                          nombre_proveedor :  this.state.nombre_proveedor,
                                          codigo_empresa: rowdata.codigo_empresa,
                                          nombre_empresa: rowdata.nombre_empresa
                                }
                                } 
                              ) 
                            } 
                          ]
          });



           }, (error) => {
             console.log(error);
           });
            


      
          });

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        const storedUsuario = localStorage.getItem("usuario");
        if (storedUsuario === null) {
	         window.location.href = '/admin/dashboard'; 
        }

      }catch(err)
      {
          window.location.href = '/admin/dashboard'; 
      }
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
    }   

render(){

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

  const tituloIntegraciones = "Integraciones del Proveedor : " + this.state.nombre_proveedor;

return (
            <div>
     <MaterialTable
      title={tituloIntegraciones}
      columns={this.state.columns}
      data={this.state.data}
      actions={this.state.actions}
      options={{
        exportButton: true
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...this.state.data];
              data.push(newData);

              let _codigo_empresa = newData.codigo_empresa;
              let _nombre_empresa = "";

              this.state.listadoEmpresas.forEach(function(element) {
                    if (element.codigo == _codigo_empresa )
                    {
                          _nombre_empresa =  element.nombre;
                    }
              });

              const secret_key_ = uuid(); 
              const token_ = uuid(); 
              const payload = { codigo_empresa: _codigo_empresa ,
                              codigo_proveedor:  this.state.codigo_proveedor };
              const secret = 'boiachimolla1985'; 
              var access_key_ = uuid(); //jwt.encode(payload, secret);

              const db = firebase.firestore();    
              db.collection('APIIntegracionProveedores').doc(_codigo_empresa+"_"+this.state.codigo_proveedor).set(
                { 
                   codigo_empresa:_codigo_empresa,
                   nombre_empresa:_nombre_empresa,
                   codigo_proveedor:   this.state.codigo_proveedor,
                   nombre_proveedor: this.state.nombre_proveedor,
                   access_key : access_key_,
                   secret_key : secret_key_,
                   token  : token_
                }
                ).then(function(result){
        
              }).catch(function(error)
              {
  
              });  
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const data = [...this.state.data];
              data.splice(data.indexOf(oldData), 1);

              let _codigo_empresa = oldData.codigo_empresa;
              let _nombre_empresa = "";

              this.state.listadoEmpresas.forEach(function(element) {
                    if (element.codigo == _codigo_empresa )
                    {
                          _nombre_empresa =  element.nombre;
                    }
              });
           
                 const db = firebase.firestore();    
                  db.collection('APIIntegracionProveedores').doc(_codigo_empresa+"_"+this.state.codigo_proveedor).delete().then(function(result)
                  {}).catch(function(error)
                  {});
            }, 600);
          }),
      }}
    />    
            </div>
        );        
    }
}

export default IntegrarProveedor;