import  React from "react";
import firestore from "firestore";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class DialogProveedorDetalleReporte extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
  
  console.log (nextProps.rowData )

    if(nextProps.rowData != null)
    {
        this.setState({   
          codigo_empresa : nextProps.rowData.codigo_empresa,
          codigo_proveedor : nextProps.rowData.codigo_proveedor,
          nombre_empresa : nextProps.rowData.nombre_empresa,
          nombre_proveedor : nextProps.rowData.nombre_proveedor,
          query : nextProps.rowData.query,
          reporte : nextProps.rowData.reporte,
          tiempo : nextProps.rowData.tiempo,
          filtro1 : nextProps.rowData.filtro1,
          tipofiltro1 : nextProps.rowData.tipofiltro1,
          filtro2 : nextProps.rowData.filtro2,
          tipofiltro2  : nextProps.rowData.tipofiltro2
        });
    }else
    {
        this.setState({   
          codigo_empresa : "",
          codigo_proveedor : "",
          nombre_empresa : "",
          nombre_proveedor : "",
          query : "",
          reporte : "",
          tiempo : "",
          filtro1 : "",
          tipofiltro1 : "",
          filtro2 : "",
          tipofiltro2  : "",
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }




 handleChangeFiltro1(e){
  this.setState({
      tipofiltro1: e.target.value
  })
}

handleChangeFiltro2(e){
  this.setState({
    tipofiltro2 : e.target.value
  })
}




 
 onDelete = (e,closeModal) => {
        e.preventDefault();
  }

  onSubmit = (e,closeModal) => {
       e.preventDefault();
       console.log(this.state);
       const db = firebase.firestore();       
        db.collection('APIIntegracionProveedoresDetalleReportes').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.reporte).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}

render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        { isDeleted ?  '' : (
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Parametros del Reporte</DialogTitle> )  
        }

        <DialogContent dividers>

        {  isDeleted ?  (   <DialogContentText> 
         <strong>    Esta seguro que desea eliminar el registro del Proveedor: {rowData.nombre} </strong>
          </DialogContentText> ) : ( <DialogContentText> 
         <strong>    Favor diligencia correctamente los parametros del reporte. </strong>
        </DialogContentText>
          )
        }

{  isDeleted ?  '' : (  
          <TextField
          autoFocus
          margin="dense"
          id="filtro1"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="Filtro 1"
          type="text"
          defaultValue={rowData != null ? rowData.filtro1 : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />
        ) 
   }

{  isDeleted ?  '' : (  
          <TextField
          autoFocus
          margin="dense"
          id="filtro1campo"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="Filtro 1 Campo"
          type="text"
          defaultValue={rowData != null ? rowData.filtro1campo : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />
        ) 
   }


{  isDeleted ?  '' : (  
         <RadioGroup aria-label="tipofiltro1" name="tipofiltro1" value={rowData != null ? rowData.tipofiltro1 : ""} onChange={this.handleChangeFiltro1.bind(this)}>
         <FormControlLabel
           value="fecha"
           control={<Radio color="primary" />}
           label="Fecha"
           labelPlacement="start"
         />
         <FormControlLabel
           value="texto"
           control={<Radio color="primary" />}
           label="Texto"
           labelPlacement="start"
         />
       </RadioGroup>
        )
     }
   
   {  isDeleted ?  '' : (      
            <TextField
            required
            margin="dense"
            id="filtro2"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Filtro 2"
            defaultValue={rowData != null ? rowData.filtro2 : ""}
            type="text"
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
       )
   }

   {
       isDeleted ?  '' : (  
        <TextField
        autoFocus
        margin="dense"
        id="filtro2campo"
        onKeyUp={this.handleChange.bind(this)}         
        onChange={this.handleChange.bind(this)}        
        label="Filtro 2 Campo"
        type="text"
        defaultValue={rowData != null ? rowData.filtro2campo : ""}
        fullWidth
        InputProps={{
            readOnly: isEdited ? false : true,
          }}
      />
      ) 
 }

{  isDeleted ?  '' : (     
 
<RadioGroup aria-label="tipofiltro2" name="tipofiltro2" value={rowData != null ? rowData.tipofiltro2 : ""} onChange={this.handleChangeFiltro2.bind(this)} 
                >
 
  <FormControlLabel
    value="fecha"
    control={<Radio color="primary" />}
    label="Fecha"
    labelPlacement="start"
  />
  <FormControlLabel
    value="texto"
    control={<Radio color="primary" />}
    label="Texto"
    labelPlacement="start"
  />
</RadioGroup>

      )
   }  



{
       isDeleted ?  '' : (  
        <TextField
        autoFocus
        margin="dense"
        id="cron"
        onKeyUp={this.handleChange.bind(this)}         
        onChange={this.handleChange.bind(this)}        
        label="Programacion ( formato cron )"
        type="text"
        defaultValue={rowData != null ? rowData.cron : ""}
        fullWidth
        InputProps={{
            readOnly: isEdited ? false : true,
          }}
      />
      ) 
 }



        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
          <Button style={{color :"white"}}  onClick={closeModal} color="primary">
            Cerrar
          </Button>
          </td>
     
     {
         isDeleted ? (
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
            <Button  style={{color :"#26c6da"}}  onClick={(e) => this.onDelete(e,closeModal)} color="primary">
             Aceptar
          </Button></td>) : ''
     }

     {
       isEdited ? (
        <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}  onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button></td>) : ''
     }
     </tr>
     </table>

        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogProveedorDetalleReporte;