import  React from "react";
import firestore from "firestore";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class DialogProveedorDetalle extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
  
  console.log (nextProps.rowData )


 

    if(nextProps.rowData != null)
    {
        this.setState({   
          codigo_empresa : nextProps.rowData.codigo_empresa,
          codigo_proveedor : nextProps.rowData.codigo_proveedor,
          nombre_empresa : nextProps.rowData.nombre_empresa,
          nombre_proveedor : nextProps.rowData.nombre_proveedor,
          query : nextProps.rowData.query,
          operacion : nextProps.rowData.operacion,
          url :  nextProps.rowData.url,
          metodo :  nextProps.rowData.metodo,
          tiempo : nextProps.rowData.tiempo,
          servicio :  nextProps.rowData.servicio,
          accion :  nextProps.rowData.accion,
          path : nextProps.rowData.path  == null ? "" :nextProps.rowData.path
        });
    }else
    {
        this.setState({   
          codigo_empresa : "",
          codigo_proveedor : "",
          nombre_empresa : "",
          nombre_proveedor : "",
          query : "",
          operacion : "",
          url : "",
          metodo : "",
          tiempo : "",
          servicio: "",
          accion : "",
          path : ""
        });
    }
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }




 handleChangeFiltro1(e){
  this.setState({
      metodo: e.target.value
  })
}

handleChangeFiltro2(e){
  this.setState({
      accion: e.target.value
  })
}

 onDelete = (e,closeModal) => {
        e.preventDefault();
  }

  onSubmit = (e,closeModal) => {
       e.preventDefault();
       console.log(this.state);
       const db = firebase.firestore();       
        db.collection('APIIntegracionProveedoresDetalle').doc(this.state.codigo_empresa+"_"+this.state.codigo_proveedor+"_"+this.state.servicio).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}

render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        { rowData.operacion == "consulta"  ?  '' : (
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Parametros de configuracion de Exportacion</DialogTitle> )  
        }

        <DialogContent dividers>
        {  rowData.operacion == "consulta"  ?  (   <DialogContentText> 
         <strong>  la configuracion  de tu servicio no requiere esta accion.</strong>
          </DialogContentText> ) : ( <DialogContentText> 
         <strong>    Favor diligencia correctamente los parametros de configuracion de la Exportacion. </strong>
        </DialogContentText>
          )
        }

{  rowData.operacion == "consulta"  ?  '' : (  
          <TextField
          autoFocus
          margin="dense"
          id="url"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="URL Servicio"
          type="text"
          defaultValue={rowData != null ? rowData.url : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />
        ) 
   }


{  rowData.operacion == "consulta"  ?  '' : (  
<label> Metodo</label>
    )
}

{  rowData.operacion == "consulta"  ?  '' : (  
         <RadioGroup aria-label="metodo" name="metodo" value={rowData != null ? rowData.metodo : ""} onChange={this.handleChangeFiltro1.bind(this)}>

         <FormControlLabel
           value="get"
           control={<Radio color="primary" />}
           label="GET"
           labelPlacement="start"
         />
         <FormControlLabel
           value="post"
           control={<Radio color="primary" />}
           label="POST"
           labelPlacement="start"
         />
         <FormControlLabel
           value="put"
           control={<Radio color="primary" />}
           label="PUT"
           labelPlacement="start"
         />
       </RadioGroup>
        )
     }


{  rowData.operacion == "consulta"  ?  '' : (  
  <label> Tipo de Exportacion</label>
    )
}


{  rowData.operacion == "consulta"  ?  '' : (  



         <RadioGroup aria-label="accion" name="accion" value={this.state == null ? rowData.accion : ( this.state.accion != "" ? this.state.accion : rowData.accion) } onChange={this.handleChangeFiltro2.bind(this)}>
         <FormControlLabel
           value="1"
           control={<Radio color="primary" />}
           label="Todos los Registros"
           labelPlacement="start"
         />
         <FormControlLabel
           value="0"
           control={<Radio color="primary" />}
           label="Solo los Cambios"
           labelPlacement="start"
         />
       </RadioGroup>
        )
     }

   
        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>

          <Button   style={{color :"white"}}   onClick={closeModal} color="primary">
            Cerrar
          </Button>
     
          </td>
    

     {
     rowData.operacion == "exportacion"  &&    isEdited ? (
      <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button   style={{color :"#26c6da"}}    onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button></td>) : ''
     }

     </tr>
     </table>
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogProveedorDetalle;