import  React from "react";
import firebase from "firebase";
import MaterialTable from "material-table";
import DialogUsuario from "views/Usuario/DialogUsuario.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

class Reporte extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        data: [] ,
        modalIsOpen: false,
        parametrosReporte :[],
        isEdited: false,
        rowData: {},
        isDeleted: false 
      };

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
         window.location.href = '/admin/dashboard'; 
      }

       const db = firebase.firestore();
        db.collection('APIIntegracionProveedoresDetalleReportes')
        .onSnapshot((snapshot) => {
          const _data = snapshot.docs.map(doc => doc.data());

          let _data_total = []

          let _data_cartera = [];
          let _data_costos = [];
          let _data_reportes =[];

          _data.forEach(__element => {
          
            if(__element.servicio == "asignacion_cupos" || __element.servicio == "cupo" || __element.servicio == "clientes_bloqueo" )
            {
              _data_cartera.push(__element);
            }

            if(__element.servicio == "listaprecios" || __element.servicio == "descuentos" )
            {
              _data_costos.push(__element);
            }
            _data_reportes.push(__element);
          });


          const rolusuario = JSON.parse(storedUsuario).rol;
          if(rolusuario == "reportes")
          {
             _data_total = _data_reportes;
          }else if(rolusuario == "cartera")
          {
            _data_total = _data_cartera;
          }else if(rolusuario == "costos")
          {
            _data_total = _data_costos;
          }else
          {
            _data_total = _data_reportes;
          }

          let actionsdatasend  = [];
         
          actionsdatasend.push({
            icon: 'search',
            tooltip: 'Visualiar reporte',
            onClick: (event,rowdata) => {
              


                    const dataRef = db.collection('APIIntegracionProveedoresDetalleConfiguracion')
                    .where('codigo_proveedor', '==', rowdata.codigo_proveedor)
                    .where('codigo_empresa','==',rowdata.codigo_empresa)
                    .where('servicio','==',rowdata.servicio)
                    dataRef.onSnapshot(snapshot => {
                    const _dataq = snapshot.docs.map(doc => doc.data());
                    
                    console.log(_dataq);

                    this.props.history.push({
                      pathname : '/admin/reportesdetalle',
                      state :{
                      codigo_proveedor : rowdata.codigo_proveedor,
                      nombre_proveedor : rowdata.nombre_proveedor,
                      codigo_empresa : rowdata.codigo_empresa,
                      nombre_empresa : rowdata.nombre_empresa,
                      servicio  :rowdata.servicio,
                      reporte : rowdata.reporte,
                      parametrosReporte :  _dataq,
                      check : rowdata.check
                      }
                      } 
                    )
                      
                
                    }, (error) => {
                      console.log(error);
                    });
          } 
          });

        

          if(rolusuario != "cartera")
          {

          actionsdatasend.push(
          {
            icon: 'build',
            tooltip: 'Configuracion Avanzada',
            onClick: (event,rowdata) =>   this.props.history.push({
              pathname : '/admin/integrarproveedordetalleconfiguracion',
              state :{
              codigo_proveedor : rowdata.codigo_proveedor,
              nombre_proveedor : rowdata.nombre_proveedor,
              codigo_empresa : rowdata.codigo_empresa,
              nombre_empresa : rowdata.nombre_empresa,
              servicio  :rowdata.servicio
          
              }
              } 
            ) 
          });
          }

 

          this.setState({ columns: [
                            { title: 'Reporte', field: 'reporte' , editable: 'never' },
                           
                            
                                   ],
                          data: _data_total
                          ,actions : actionsdatasend
          });
        }, (error) => {
          console.log(error);
        });
        
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
      this.setState({modalIsOpen: true});
    }
   
    afterOpenModal() {
      // references are now sync'd and can be accessed.
      this.subtitle.style.color = '#f00';
    }
   
    closeModal() {
      this.setState({modalIsOpen: false});
    }   

render(){
 
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

return (
            <div>
              <MaterialTable
                  title="Listado de Reportes"
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={this.state.actions}
                />
   
            </div>
        );        
    }
}

export default Reporte;