import  React from "react";
import firestore from "firestore";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

class DialogUsuario extends React.Component {

constructor (props) {
        super (props)       
}

componentWillReceiveProps = (nextProps) => {
    var  _fecha_creacion = firebase.firestore.FieldValue.serverTimestamp()

    if(nextProps.rowData != null)
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            cedula: nextProps.rowData.cedula ,
            email: nextProps.rowData.email,
            nombre: nextProps.rowData.nombre,
            clave: nextProps.rowData.clave,
            rol :  ""
        });
    }else
    {
        this.setState({   
            estado :true,
            fecha_creacion: _fecha_creacion,
            fecha_actualizacion : _fecha_creacion,
            clave: "",
            cedula: "",
            email: "",
            nombre: "",
            rol : ""
        });
    }
}





handleChangeRol(e){
  this.setState({
      rol : e.target.value
  })
}


 handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

 
 onDelete = (e,closeModal) => {
        e.preventDefault();
        const db = firebase.firestore();    
        
         db.collection('APIUsuarios').doc(this.state.cedula).delete().then(function(result)
         {
             closeModal();
         }).catch(function(error)
         {
              closeModal();
         });
  }

  onSubmit = (e,closeModal) => {
       e.preventDefault();
       console.log(this.state);
       const db = firebase.firestore();          
        db.collection('APIUsuarios').doc(this.state.cedula).set(this.state).then(function(result){
            closeModal();
        }).catch(function(error)
        {
            closeModal();
        });  
}

render()
{
const {modalIsOpen, closeModal,isEdited,rowData,isDeleted} = this.props;

 
var martginTop  = {marginTop : "20px"};
var styleClass = { width:"100%"};

console.log("DELETED: "+isDeleted);

    return(
        <Dialog open={modalIsOpen} onClose={closeModal} aria-labelledby="form-dialog-title">

        { isDeleted ?  '' : (
        <DialogTitle style={{backgroundColor :"#26c6da" ,color:"white"}} id="form-dialog-title">Detalle de Usuario</DialogTitle> )  
        }

        <DialogContent dividers>

        {  isDeleted ?  (   <DialogContentText> 
         <strong>    Esta seguro que desea eliminar el registro del Usuario: {rowData.nombre} </strong>
          </DialogContentText> ) : ( <DialogContentText> 
         <strong>    Favor diligencia correctamente los datos del usuario. </strong>
        </DialogContentText>
          )
        }

{  isDeleted ?  '' : (  

  
  rowData != null && isEdited ? (
         <TextField
            autoFocus
            disabled 
            margin="dense"
            id="cedula"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Cedula"
            type="text"
            defaultValue={rowData != null ? rowData.cedula : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )   :
        (
          <TextField
          autoFocus
          margin="dense"
          id="cedula"
          onKeyUp={this.handleChange.bind(this)}         
          onChange={this.handleChange.bind(this)}        
          label="Cedula"
          type="text"
          defaultValue={rowData != null ? rowData.cedula : ""}
          fullWidth
          InputProps={{
              readOnly: isEdited ? false : true,
            }}
        />

        )


      )
   }

{  isDeleted ?  '' : (  
         <TextField
            required
            margin="dense"
            id="nombre"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Nombre"
            type="text"
        
            defaultValue={rowData != null ? rowData.nombre : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
        )
     }
   
   {  isDeleted ?  '' : (      
            <TextField
            required
            margin="dense"
            id="clave"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Clave"
            defaultValue={rowData != null ? rowData.clave : ""}
            type="text"
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
       )
   }

   {  isDeleted ?  '' : (     
            <TextField
            required
            margin="dense"
            id="email"
            onKeyUp={this.handleChange.bind(this)}         
            onChange={this.handleChange.bind(this)}        
            label="Email"
            type="text"
            defaultValue={rowData != null ? rowData.email : ""}
            fullWidth
            InputProps={{
                readOnly: isEdited ? false : true,
              }}
          />
      )
   }  

 
{ isDeleted ? '' : (
 
<InputLabel  htmlFor="outlined-age-native-simple" style={martginTop}>
          Rol
        </InputLabel>

)  
}
{ isDeleted ? '' : (
 
<Select style ={styleClass}

          inputProps={{
            name: 'rol',
            id: 'rol'
          }}
          onChange={this.handleChangeRol.bind(this)}  value={ this.state && this.state.rol ? this.state.rol : (rowData ? rowData.rol : '')}  
        >
          <MenuItem value={"admin"}>Administrador</MenuItem>
          <MenuItem value={"cartera"}>Cartera</MenuItem>
          <MenuItem value={"costos"}>Costos</MenuItem>
          <MenuItem value={"reportes"}>Reportes</MenuItem>
          <MenuItem value={"clientes"}>Clientes</MenuItem>
        </Select>

)  
}



        </DialogContent>
        <DialogActions style={{backgroundColor :"white" ,color:"white", padding:"0px"}}>
        <table style={{width: "100%"}}>
        <tr>
        <td align="center" style={{backgroundColor :"#26c6da"}}>
          <Button style={{color :"white"}}  onClick={closeModal} color="primary">
            Cerrar
          </Button>
          </td> 
     
     {
         isDeleted ? (
          <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
            <Button  style={{color :"#26c6da"}}   onClick={(e) => this.onDelete(e,closeModal)} color="primary">
             Aceptar
          </Button></td>) : ''
     }

     {
       isEdited ? (
        <td  align="center" style={{ backgroundColor:"white", color :"#26c6da" }}> 
          <Button  style={{color :"#26c6da"}}  onClick={(e) => this.onSubmit(e,closeModal)} color="primary">
            Guardar
          </Button></td>) : ''
     }

     </tr>
     </table>
        </DialogActions>
      </Dialog>
    )
 }
}

export default DialogUsuario;